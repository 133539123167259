html {
  height: 100%;
}

body {
  background: url("../img/background.jpg") $primary no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  min-height: 35rem;
  position: relative;
}

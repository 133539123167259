.Masthead {
  padding-bottom: 3rem;
  position: relative;
  overflow: hidden;
  z-index: 2;
  &-background {
    background-color: fade-out($primary, 0.2);
    bottom: 0;
    height: 100%;
    left: 0;
    min-height: 35rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: skewY(4deg);
    transform-origin: bottom right;
    width: 100%;
  }
  &-content {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
      strong {
        font-weight: 700;
      }
    }
  }
}

@media (min-width: 768px) {
  .Masthead {
    height: 100vh;
    min-height: 0;
    padding-bottom: 0;
    width: 40.5rem;
    &-background {
      min-height: 0;
      transform: skewX(-8deg);
      transform-origin: top right;
    }
    &-content {
      padding-left: 3rem;
      padding-right: 10rem;
      h1 {
        font-size: 3.5rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
}

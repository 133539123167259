.NewsletterForm {
  .input-group-newsletter {
    .btn-secondary {
      background-color: $secondary;
      border-color: $secondary;
      &:active,
      &:focus,
      &:hover {
        background-color: darken($secondary, 10%) !important;
        border-color: darken($secondary, 10%) !important;
      }
    }
    input {
      font-size: 1rem;
      font-weight: 300 !important;
      height: auto;
      padding: 1rem;
    }
    button {
      padding: 1rem;
      font-size: 0.8rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.SocialIcons {
  position: absolute;
  margin-bottom: 2rem;
  width: 100%;
  z-index: 2;
  ul {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    > li {
      margin-left: 1rem;
      margin-right: 1rem;
      display: inline-block;
      > a {
        display: block;
        color: white;
        background-color: fade-out($primary, 0.2);
        border-radius: 100%;
        font-size: 2rem;
        line-height: 4rem;
        height: 4rem;
        width: 4rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .SocialIcons {
    margin: 0;
    position: absolute;
    right: 2.5rem;
    bottom: 2rem;
    width: auto;
    ul {
      margin-top: 0;
      width: auto;
      > li {
        display: block;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
        > a {
          transition: all 0.2s ease-in-out;
          font-size: 2rem;
          line-height: 4rem;
          height: 4rem;
          width: 4rem;
          &:hover {
            background-color: $primary;
          }
        }
      }
    }
  }
}
